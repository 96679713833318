import {
  CalibrationMeasurement,
  InProcessCalibration,
  PeriodicCalibration,
  PeriodicCalibrationDetail,
} from '@interfaces';
import { createActionGroup, props } from '@ngrx/store';

export const calibrationActions = createActionGroup({
  source: 'Calibration API',

  events: {
    'Get Periodic Calibrations': props<{ companyId: string }>(),
    'Get Periodic Calibrations Success': props<{
      result: PeriodicCalibration[];
    }>(),
    'Toggle Periodic Calibration Loading': props<{ loading: boolean }>(),

    'Get Periodic Calibration By ID': props<{ id: string }>(),
    'Get Periodic Calibration By ID Success': props<{
      result: PeriodicCalibrationDetail[];
    }>(),

    'Get In Process Calibrations': props<{ id: string }>(),
    'Get In Process Calibrations Success': props<{
      result: InProcessCalibration[];
    }>(),
    'Toggle In Process Calibration Loading': props<{ loading: boolean }>(),

    'Get Measurements Data': props<{ articleId: string; batchId: string }>(),
    'Get Measurements Data Success': props<{
      result: CalibrationMeasurement[];
    }>(),
    'Toggle Measurements Data Loading': props<{ loading: boolean }>(),

    'Get In Process Calibration By ID': props<{ id: string }>(),
    'Get In Process Calibration By ID Success': props<{
      result: InProcessCalibration;
    }>(),
    'Toggle In Process Calibration By ID Loading': props<{
      loading: boolean;
    }>(),
  },
});
