import { Injectable, inject } from '@angular/core';
import { Observable, of } from 'rxjs';
import { CalibrationMeasurement } from '../interfaces';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class CalibrationService {
  private readonly _api = inject(ApiService);

  getPeriodicCalibrations(companyId: string) {
    console.info(companyId);
    return of({
      data: [
        {
          inspectionEquipmentID: 6309,
          inspectionEquipmentNr: 'CZ - 36004628/1',
          inspectionEquipmentType: 'Red rabbit / Green rabbit',
          location: 'CZ-Plant Okrisky',
          decision: 'Verwendbar',
          firstInspection: '2015-04-13T15:13:43',
          lastInspection: '2023-04-17T13:56:21.223',
          nextInspection: '2025-04-17T13:56:21.207',
          numberOfInspections: 3,
          inspectionEquipmentProduct: 'Loch im Rohr',
          instruction: 'Dummy',
          createdOn: '2025-01-14T06:51:43.1833333',
        },
        {
          inspectionEquipmentID: 6315,
          inspectionEquipmentNr: 'CZ - 36004776 / 1',
          inspectionEquipmentType: 'Red rabbit / Green rabbit',
          location: 'CZ-Plant Okrisky',
          decision: 'Nicht verwendbar',
          firstInspection: '2016-04-21T15:34:07.610',
          lastInspection: '2016-04-21T15:34:07.610',
          numberOfInspections: 1,
          inspectionEquipmentProduct: 'Dummy Gummi Tülle Position',
          instruction: 'Dummy',
          createdOn: '2025-01-14T06:51:43.1833333',
        },
        {
          inspectionEquipmentID: 6316,
          inspectionEquipmentNr: 'CZ - 36004776 / 2',
          inspectionEquipmentType: 'Red rabbit / Green rabbit',
          location: 'CZ-Plant Okrisky',
          decision: 'Nicht verwendbar',
          firstInspection: '2016-04-21T15:36:08.670',
          lastInspection: '2016-04-21T15:36:08.670',
          numberOfInspections: 1,
          inspectionEquipmentProduct: 'Dummy Gummi Tülle Position',
          instruction: 'Dummy',
          createdOn: '2025-01-14T06:51:43.1833333',
        },
      ],
    });

    // return this._api.get<PeriodicCalibration[]>(
    //   `periodic`,
    //   {
    //     params: {
    //       companyId,
    //     },
    //   },
    //   ApiEndpointType.Function,
    // );
  }

  getPeriodicCalibrationByID(periodicCalibrationId: string) {
    console.info(periodicCalibrationId);
    return of({
      data: [
        {
          periodicCalibrationId: '1',
          inspectionEquipmentID: 6309,
          inspectionEquipmentNr: 'CZ - 36004628/1',
          location: 'CZ-Plant Okrisky',
          historizedDecision: 'Verwendbar',
          historyId: 1,
          inspectionDate: '2025-01-14T06:51:43.1833333',
          eventName: 'Dummy',
          createdOn: '2025-01-14T06:51:43.1833333',
        },
      ],
    });
  }

  getInProcessCalibrations(id: string) {
    console.info(id);
    return of({
      data: [
        {
          articleId: '41302209AC',
          batchId: '161115',
          inProcessCalibrationId: 'CAE2C2DA-C4E7-4D70-84E5-21D1AFB5A0CC',
          babtecProductionOrderId: '528783-FA-03-01-01',
          orderId: 1108452,
          testingSiteId: 621,
          testingSiteName: 'PRO-CZ-ASSEMBLY',
          orderPositionId: 1705238,
          workstepDecisionId: 1,
          workstepDecision: 'Accepted',
          workstepDecisionDate: '2022-12-21T10:36:56.640',
          orderPositionManualDecision: 1,
          orderPositionSystemDecision: 1,
          orderPositionCalibrationDate: '2022-12-01T08:44:02.233',
          characteristicId: 690809,
          characteristicName: 'Funkční zkouška senzorů [Němčina]',
          characteristicType: 0,
          characteristicDecision: 1,
          inspectionEquipmentType: 'Red rabbit / Green rabbit',
          orderCharacteristicId: 6643482,
          stichNr: 1,
          measuredValueNr: 1,
          measuredValue: 1.0,
          measureDate: '2022-12-01T08:44:02.233',
          createdOn: '2025-01-14T09:09:48.8533333',
        },
        {
          articleId: '40804977',
          batchId: '254086',
          inProcessCalibrationId: '5F7E74B9-8DE5-4868-BC2A-F99926ADDF8F',
          babtecProductionOrderId: '528783-FA-03-01-01',
          orderId: 1108452,
          testingSiteId: 621,
          testingSiteName: 'PRO-CZ-ASSEMBLY',
          orderPositionId: 1705238,
          workstepDecisionId: 1,
          workstepDecision: 'Accepted',
          workstepDecisionDate: '2022-12-21T10:36:56.640',
          orderPositionManualDecision: 1,
          orderPositionSystemDecision: 1,
          orderPositionCalibrationDate: '2022-12-01T08:44:02.233',
          characteristicId: 690822,
          characteristicName: 'Funkční zkouška senzorů [Němčina]',
          characteristicType: 0,
          characteristicDecision: 1,
          inspectionEquipmentType: 'Red rabbit / Green rabbit',
          orderCharacteristicId: 6643483,
          stichNr: 1,
          measuredValueNr: 1,
          measuredValue: 1.0,
          measureDate: '2022-12-01T08:44:13.227',
          createdOn: '2025-01-14T09:09:48.8533333',
        },
        {
          articleId: '40806709',
          batchId: '255670',
          inProcessCalibrationId: 'DECE4025-4F20-4F31-9680-25C3D12E1527',
          babtecProductionOrderId: '528783-FA-03-01-01',
          orderId: 1108452,
          testingSiteId: 621,
          testingSiteName: 'PRO-CZ-ASSEMBLY',
          orderPositionId: 1725971,
          workstepDecisionId: 1,
          workstepDecision: 'Accepted',
          workstepDecisionDate: '2022-12-21T10:36:23.650',
          orderPositionManualDecision: 1,
          orderPositionSystemDecision: 1,
          orderPositionCalibrationDate: '2022-12-21T10:36:10.357',
          characteristicId: 690816,
          characteristicName: 'Funkční zkouška senzorů [Němčina]',
          characteristicType: 0,
          characteristicDecision: 1,
          inspectionEquipmentType: 'Red rabbit / Green rabbit',
          orderCharacteristicId: 6737658,
          stichNr: 1,
          measuredValueNr: 1,
          measuredValue: 1.0,
          measureDate: '2022-12-21T10:36:10.357',
          createdOn: '2025-01-14T09:09:48.8533333',
        },
        {
          articleId: '40806709',
          batchId: '255670',
          inProcessCalibrationId: 'BCEF2F8B-CA5A-4D59-A541-874492ED5509',
          babtecProductionOrderId: '529069-FA-03-01-01',
          orderId: 1110328,
          testingSiteId: 621,
          testingSiteName: 'PRO-CZ-ASSEMBLY',
          orderPositionId: 1708744,
          workstepDecisionId: 4,
          workstepDecision: 'Rejected',
          workstepDecisionDate: '2022-12-08T05:23:31.423',
          orderPositionManualDecision: 1,
          orderPositionSystemDecision: 1,
          orderPositionCalibrationDate: '2022-12-05T10:43:59.450',
          characteristicId: 690822,
          characteristicName: 'Funkční zkouška senzorů [Němčina]',
          characteristicType: 0,
          characteristicDecision: 1,
          inspectionEquipmentType: 'Red rabbit / Green rabbit',
          orderCharacteristicId: 6660184,
          stichNr: 1,
          measuredValueNr: 1,
          measuredValue: 1.0,
          measureDate: '2022-12-05T10:44:01.920',
          createdOn: '2025-01-14T09:09:48.8533333',
        },
      ],
    });
  }

  getMeasurementsData(
    articleId: string,
    batchId: string,
  ): Observable<{ data: CalibrationMeasurement[] }> {
    console.info(articleId, batchId);
    return of({
      data: [
        {
          mkId: 1,
          name: 'Dummy',
          characteristicType: 'Dummy',
          measureUnit: 'mm',
          shouldValue: 1.0,
          measuredValue: 1.0,
          mkUsg: 1.0,
          mkOsg: 1.0,
          pruefmittelType: 'Messschiene',
          result: 'OK',
          measureDate: '2023-09-15 07:54:10.027',
        },
        {
          mkId: 2,
          name: 'Dummy',
          characteristicType: 'Dummy',
          measureUnit: 'mm',
          shouldValue: 1.0,
          measuredValue: 1.0,
          mkUsg: 1.0,
          mkOsg: 1.0,
          pruefmittelType: 'Messschiene',
          result: 'OK',
          measureDate: '2023-09-15 07:54:10.027',
        },
        {
          mkId: 3,
          name: 'Dummy',
          characteristicType: 'Dummy',
          measureUnit: 'mm',
          shouldValue: 1.0,
          measuredValue: 1.0,
          mkUsg: 1.0,
          mkOsg: 1.0,
          pruefmittelType: 'Messschiene',
          result: 'OK',
          measureDate: '2023-09-15 07:54:10.027',
        },
        {
          mkId: 4,
          name: 'Dummy',
          characteristicType: 'Dummy',
          measureUnit: 'mm',
          shouldValue: 1.0,
          measuredValue: 1.0,
          mkUsg: 1.0,
          mkOsg: 1.0,
          pruefmittelType: 'Messschiene',
          result: 'OK',
          measureDate: '2023-09-15 07:54:10.027',
        },
      ],
    });
  }

  getInProcessCalibrationByID(id: string) {
    console.info(id);
    return of({
      data: {
        articleId: '40806709',
        batchId: '255670',
        inProcessCalibrationId: 'CAE2C2DA-C4E7-4D70-84E5-21D1AFB5A0CC',
        babtecProductionOrderId: '528783-FA-03-01-01',
        orderId: 1108452,
        testingSiteId: 621,
        testingSiteName: 'PRO-CZ-ASSEMBLY',
        orderPositionId: 1705238,
        workstepDecisionId: 1,
        workstepDecision: 'Accepted',
        workstepDecisionDate: '2022-12-21T10:36:56.640',
        orderPositionManualDecision: 1,
        orderPositionSystemDecision: 1,
        orderPositionCalibrationDate: '2022-12-01T08:44:02.233',
        characteristicId: 690809,
        characteristicName: 'Funkční zkouška senzorů [Němčina]',
        characteristicType: 0,
        characteristicDecision: 1,
        inspectionEquipmentType: 'Red rabbit / Green rabbit',
        orderCharacteristicId: 6643482,
        stichNr: 1,
        measuredValueNr: 1,
        measuredValue: 1.0,
        measureDate: '2022-12-01T08:44:02.233',
        createdOn: '2025-01-14T09:09:48.8533333',
      },
    });
  }
}
