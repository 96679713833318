import { Injectable, inject } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, from, map, switchMap, tap, withLatestFrom } from 'rxjs';

import { AlertingService } from '@frontend-workspace/shared/src/lib/services/alerting.service';
import { PartsService } from '@frontend-workspace/shared/src/lib/services/parts.service';
import { selectUser } from '../user';
import {
  fetchNotifications,
  fetchNotificationsSuccess,
  fetchSingleItemsByProductionOrder,
  fetchSingleItemsByProductionOrderSuccess,
  markNotificationAsRead,
  markNotificationAsReadSuccess,
  toggleSingleItemsByProductionOrderLoading,
} from './ui.actions';

@Injectable()
export class AppEffects {
  private actions$ = inject(Actions);

  private readonly _store = inject(Store);
  private readonly _partsService = inject(PartsService);
  private readonly _actions$ = inject(Actions);
  private readonly _alertingService = inject(AlertingService);

  fetchSingleItemsByProductionOrder$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(fetchSingleItemsByProductionOrder),
        tap(() =>
          this._store.dispatch(
            toggleSingleItemsByProductionOrderLoading({ loading: true }),
          ),
        ),
        switchMap(({ productionOrderId }) =>
          from(
            this._partsService.getSingleItemsByProductionOrder(
              productionOrderId,
            ),
          ).pipe(
            map((response) => response.data),
            map((items) =>
              fetchSingleItemsByProductionOrderSuccess({
                items,
                productionOrderId,
              }),
            ),
            tap(() =>
              this._store.dispatch(
                toggleSingleItemsByProductionOrderLoading({ loading: false }),
              ),
            ),
          ),
        ),
      ),
  );

  getNotifications$ = createEffect(
    (): Observable<Action> =>
      this._actions$.pipe(
        ofType(fetchNotifications),
        withLatestFrom(this._store.select(selectUser)),
        switchMap(() => {
          return this._alertingService.getNotifications().pipe(
            map((response) => {
              const result = response.data === null ? [] : response.data;
              return fetchNotificationsSuccess({
                result: result,
              });
            }),
          );
        }),
      ),
  );

  markNotificationAsRead$ = createEffect(() =>
    this._actions$.pipe(
      ofType(markNotificationAsRead),
      switchMap(({ id }) => {
        return this._alertingService.markNotificationAsRead(id).pipe(
          map((response) => {
            return markNotificationAsReadSuccess({
              result: response.data || null,
            });
          }),
        );
      }),
    ),
  );
}
