import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from './ui.reducer';

export const selectApp = createFeatureSelector<AppState>('app');

export const selectedPart = () =>
  createSelector(selectApp, (state: AppState) => state.selectedPart);

export const selectSingleItemsByProductionOrder = (productionOrderId: string) =>
  createSelector(
    selectApp,
    (state: AppState) => state.singleItemsByProductionOrder[productionOrderId],
  );

export const selectSingleItemsByProductionOrderLoading = () =>
  createSelector(
    selectApp,
    (state: AppState) => state.singleItemsByProductionOrderLoading,
  );

export const selectNotifications = () =>
  createSelector(selectApp, (state: AppState) => state?.notifications);
