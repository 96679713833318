import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { Router } from '@angular/router';

import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AuthService } from '@core-app/services/auth.service';
import { FeatureFlagsService } from '@core-app/services/feature-flags.service';
import {
  markNotificationAsRead,
  selectNotifications,
} from '@core-app/state/ui';
import { selectUser } from '@core-app/state/user';
import { AlertingTab } from '@enums';
import { PortalNotification, User } from '@interfaces';
import { Store } from '@ngrx/store';
import { filter, map } from 'rxjs';

const NOTIFICATION_MESSAGE_LENGTH = 100;

@Component({
  selector: 'fip-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent {
  @Input() showLogo?: boolean = false;
  @Input() unCollapsed = false;
  @Output() collapsed = new EventEmitter<boolean>();

  readonly featureFlags = inject(FeatureFlagsService);
  private readonly _router = inject(Router);

  notifications$ = this._store.select(selectNotifications()).pipe(
    map(
      (notifications) =>
        notifications?.map((notification) => ({
          ...notification,
          message:
            // Truncate message length
            notification.message.length > NOTIFICATION_MESSAGE_LENGTH
              ? `${notification.message.substring(
                  0,
                  NOTIFICATION_MESSAGE_LENGTH,
                )}...`
              : notification.message,
        })),
    ),
  );
  unreadCount$ = this.notifications$.pipe(
    map(
      (notifications) =>
        notifications?.filter((notification) => !notification.isRead).length,
    ),
  );

  user: User | null = null;

  constructor(
    private readonly _store: Store,
    readonly router: Router,
    private readonly _authService: AuthService,
  ) {
    this._store
      .select(selectUser)
      .pipe(
        takeUntilDestroyed(),
        filter((user) => !!user?.firstName || !!user?.lastName),
      )
      .subscribe((user) => {
        this.user = user;
      });
  }

  logout(): void {
    this._authService.logout();
  }

  collapseSidebar(): void {
    this.collapsed.emit(true);
  }

  reloadPermissions(): void {
    const localStorageKeys = Object.keys(localStorage);

    localStorageKeys.forEach((item) => {
      if (item != 'serialSearch' && item != 'partSearch') {
        localStorage.removeItem(item);
      }
    });

    if (this.router.url.split('/').indexOf('dashboard') != -1) {
      location.reload();
    } else {
      this.router.navigate(['/dashboard', '']);
    }
  }

  navigateToAlert(notification: PortalNotification) {
    if (!notification.isRead) {
      this._store.dispatch(markNotificationAsRead({ id: notification.rowKey }));
    }

    // This new parameters can be used to filter on the table
    this._router.navigate(['alerting'], {
      queryParams: {
        selectedTab: AlertingTab.Alerts,
        from: notification.createdAt,
        to: notification.createdAt,
        monitorType: notification.monitorType,
      },
    });
  }
}
