import { Injectable, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  ActivatedRoute,
  NavigationEnd,
  NavigationStart,
  Params,
  Router,
} from '@angular/router';
import { DailyProductionTargetMissedGeneralConfigModalComponent } from '@core-app/alerting/monitors/daily-production-target-missed/daily-production-target-missed-general-config-modal/daily-production-target-missed-general-config-modal.component';
import { DailyProductionTargetMissedModalComponent } from '@core-app/alerting/monitors/daily-production-target-missed/daily-production-target-missed-modal/daily-production-target-missed-modal.component';
import { FailureDuringIncomingInspectionModalComponent } from '@core-app/alerting/monitors/failure-during-incoming-inspection/failure-during-incoming-inspection-modal/failure-during-incoming-inspection-modal.component';
import { ReductionShipmentSizeModalComponent } from '@core-app/alerting/monitors/reduction-shipment-size/reduction-shipment-size-modal/reduction-shipment-size-modal.component';
import { ArticleSelectorModalComponent } from '@core-app/dashboard/search/modals/article-selector/article-selector.modal';
import { CalibrationMeasurementsModalComponent } from '@core-app/dashboard/search/modals/calibration-measurements/calibration-measurements.modal';
import { DocumentsModalComponent } from '@core-app/dashboard/search/modals/documents/documents.modal';
import { EquipmentSearchModalComponent } from '@core-app/dashboard/search/modals/equipment-search/equipment-search.modal';
import { ImageModalComponent } from '@core-app/dashboard/search/modals/image/image.modal';
import { PartSelectorModalComponent } from '@core-app/dashboard/search/modals/part-selector/part-selector.modal';
import { ProductionPlannedBomModalComponent } from '@core-app/dashboard/search/modals/production-planned-bom/production-planned-bom.modal';
import { SPCArticleSearchModalComponent } from '@core-app/dashboard/search/modals/spc-article-search/spc-article-search.modal';
import { SupportModalComponent } from '@core-app/dashboard/search/modals/support/support.modal';
import { ConfirmModalComponent } from '@core-app/production/confirm-modal/confirm-modal.component';
import { OrderInformationModalComponent } from '@core-app/production/order-information-modal/order-information-modal.component';
import { PartsModalComponent } from '@core-app/production/parts-modal/parts-modal.component';
import { showModal } from '@core-app/state/ui';
import { CustomModalComponent } from '@frontend-workspace/shared/src';
import { ModalKey } from '@frontend-workspace/shared/src/lib/types/modal-key';
import { Actions, ofType } from '@ngrx/effects';
import { filter } from 'rxjs';
import { ArticleSearchIncomingInspectionsModalComponent } from '../dashboard/search/modals/article-search-incoming/article-search-incoming.modal';
import { ArticleSearchInlineInspectionsModalComponent } from '../dashboard/search/modals/article-search-inline/article-search-inline.modal';
import { ArticleSearchPeriodicInspectionsModalComponent } from '../dashboard/search/modals/article-search-periodic/article-search-periodic.modal';
import { MeasurementsModalComponent } from '../dashboard/search/modals/measurements/measurements.modal';
import { PartSearchIncomingComponent } from '../dashboard/search/modals/part-search-incoming/part-search-incoming.component';
import { PartSearchInlineComponent } from '../dashboard/search/modals/part-search-inline/part-search-inline.component';
import { PartSearchPeriodicComponent } from '../dashboard/search/modals/part-search-periodic/part-search-periodic.component';
import { PartSearchModalComponent } from '../dashboard/search/modals/part-search/part-search.modal';

const QUERY_PARAM_KEY = 'openModal';

interface ModalComponent {
  key: ModalKey;
  title: string;
  subtitle?: string;
  component: unknown;
}

const MODALS: ModalComponent[] = [
  {
    key: 'part-search_part-search',
    title: 'Part Search',
    component: PartSearchModalComponent,
  },
  {
    key: 'part-search_equipment-search',
    title: 'Equipment Search',
    component: EquipmentSearchModalComponent,
  },
  {
    key: 'inspections_incoming-inspections',
    title: 'Article Search',
    subtitle: 'Incoming Inspections',
    component: ArticleSearchIncomingInspectionsModalComponent,
  },
  {
    key: 'inspections_inline-inspections',
    title: 'Article Search',
    subtitle: 'Inline Inspections',
    component: ArticleSearchInlineInspectionsModalComponent,
  },
  {
    key: 'inspections_periodic-inspections',
    title: 'Article Search',
    subtitle: 'Periodic Inspections',
    component: ArticleSearchPeriodicInspectionsModalComponent,
  },
  {
    key: 'part-search_incoming-inspections',
    title: 'Part Incoming Inspections Search',
    component: PartSearchIncomingComponent,
  },
  {
    key: 'part-search_periodic-inspections',
    title: 'Part Periodic Inspections Search',
    component: PartSearchPeriodicComponent,
  },
  {
    key: 'part-search_inline-inspections',
    title: 'Part Inline Inspection Search',
    component: PartSearchInlineComponent,
  },
  {
    key: 'inspections_measurements',
    title: 'Measurements',
    component: MeasurementsModalComponent,
  },
  {
    key: 'article_selector',
    title: 'Article Selector',
    component: ArticleSelectorModalComponent,
  },
  {
    key: 'image',
    title: 'Image',
    component: ImageModalComponent,
  },
  {
    key: 'documents',
    title: 'Documents',
    component: DocumentsModalComponent,
  },
  {
    key: 'production_planned_bom',
    title: 'Stock',
    component: ProductionPlannedBomModalComponent,
  },
  {
    key: 'support',
    title: 'Support',
    component: SupportModalComponent,
  },
  {
    key: 'spc_article-search',
    title: 'Article Search',
    subtitle: 'SPC Article Selector',
    component: SPCArticleSearchModalComponent,
  },
  {
    key: 'part-selector',
    title: 'Part Selector',
    subtitle: 'Select a Part ID',
    component: PartSelectorModalComponent,
  },
  {
    key: 'new-reduction_shipment_size',
    title: 'Create New "Reduction in Shipment Size" Monitor',
    component: ReductionShipmentSizeModalComponent,
  },
  {
    key: 'new-daily_production_target_missed_by_x_percent',
    title: 'Create New "Daily Production Target Missed" Monitor',
    component: DailyProductionTargetMissedModalComponent,
  },

  {
    key: 'general-config-daily_production_target_missed_by_x_percent',
    title: 'Configure Monitor',
    component: DailyProductionTargetMissedGeneralConfigModalComponent,
  },

  {
    key: 'order-information',
    title: 'Detailed Order Information',
    component: OrderInformationModalComponent,
  },
  {
    key: 'order-parts',
    title: 'Single Items related to Batch',
    component: PartsModalComponent,
  },
  {
    key: 'new-failure_during_incoming_inspection',
    title: 'Create New "Failure During Incoming Inspection" Monitor',
    component: FailureDuringIncomingInspectionModalComponent,
  },
  {
    key: 'confirm',
    title: 'Confirm',
    component: ConfirmModalComponent,
  },
  {
    key: 'calibration-measurements',
    title: 'Calibration Measurements',
    component: CalibrationMeasurementsModalComponent,
  },
];

export const MODAL_SIZES = {
  smallest: '400px',
  medium: '50%',
  large: '70%',
};

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  private _router = inject(Router);
  private _dialog: MatDialog = inject(MatDialog);
  private _actions$ = inject(Actions);
  private _activatedRoute = inject(ActivatedRoute);
  private _dialogRef: MatDialogRef<CustomModalComponent>;

  init() {
    this.initOpen();
    this.initClose();
  }

  initOpen() {
    this.handleQueryParams();
    this.handleAction();
  }

  // Open dialog if query param 'openModal' is present
  handleQueryParams() {
    this._router.events
      .pipe(
        takeUntilDestroyed(),
        filter((event) => event instanceof NavigationEnd),
      )
      .subscribe(() => {
        const params = this._activatedRoute.snapshot.queryParams;
        const modalKey = params[QUERY_PARAM_KEY];
        const data = params['data'] || '{}';
        const width = (params['width'] || 'large') as keyof typeof MODAL_SIZES;

        if (modalKey) {
          this.openDialog(
            modalKey,
            data,
            MODAL_SIZES[width || 'large'] || width,
          );
        }
      });
  }

  // Handle action from store
  handleAction() {
    this._actions$
      .pipe(ofType(showModal), takeUntilDestroyed())
      .subscribe((action) => {
        const queryParams: Params = {
          [QUERY_PARAM_KEY]: action.modalKey,
          data: JSON.stringify(action.data),
          width: action.width,
        };

        // Add query param to url
        this._router.navigate([], {
          relativeTo: this._activatedRoute,
          queryParams,
          queryParamsHandling: 'merge',
        });
      });
  }

  // Close dialog on navigation
  initClose() {
    this._router.events
      .pipe(
        takeUntilDestroyed(),
        filter((event) => event instanceof NavigationStart),
        filter(() => !!this._dialogRef),
      )
      .subscribe(() => {
        this._dialog.closeAll();
      });
  }

  removeQueryParams() {
    this._router.navigate([], {
      relativeTo: this._activatedRoute,
      queryParams: { [QUERY_PARAM_KEY]: null, data: null, width: null },
      queryParamsHandling: 'merge',
    });
  }

  openDialog(
    modalKey: ModalKey,
    data?: string,
    width: string = MODAL_SIZES.large,
  ) {
    const modal = MODALS.find((modal) => modal.key === modalKey);
    const parsedData = JSON.parse(data || '{}');

    if (!modal) {
      console.error(`No modal found with key '${modalKey}'`);
      return;
    }

    this._dialogRef = this._dialog.open(CustomModalComponent, {
      data: {
        component: modal.component,
        title: parsedData.title ?? modal.title,
        subtitle: modal.subtitle,
        nestedData: parsedData,
      },
      width,
    });

    // Remove query param on modal close
    this._dialogRef.afterClosed().subscribe(() => {
      this.removeQueryParams();
    });
  }

  get dialogRef() {
    return this._dialogRef;
  }
}
