import { PartsSummaryDTO, PortalNotification } from '@interfaces';
import { Action, createReducer, on } from '@ngrx/store';
import * as AppActions from './ui.actions';

export const APP_FEATURE_KEY = 'app';

export interface SelectedPartData {
  serialNumber: string;
  selectedProductionOrderId: string;
}

export interface AppState {
  selectedPart: SelectedPartData;
  singleItemsByProductionOrder: { [key: string]: PartsSummaryDTO[] | null };
  singleItemsByProductionOrderLoading: boolean;
  notifications: null | PortalNotification[];
}

const initialAppState: AppState = {
  selectedPart: <SelectedPartData>{},
  singleItemsByProductionOrder: {},
  singleItemsByProductionOrderLoading: false,
  notifications: null,
};

const reducer = createReducer(
  initialAppState,
  on(AppActions.selectPart, (state, { selectedPart }) => {
    return {
      ...state,
      selectedPart: selectedPart,
    };
  }),
  on(
    AppActions.fetchSingleItemsByProductionOrderSuccess,
    (state, { items, productionOrderId }) => {
      const singleItems = { ...state.singleItemsByProductionOrder };

      // Sort by externalSingleItemId alphabetically
      let newItems = [] as PartsSummaryDTO[];

      if (items) {
        newItems = [...items];

        newItems = newItems?.sort(
          (a, b) =>
            a.externalSingleItemId?.localeCompare(b.externalSingleItemId || ''),
        );
      }
      singleItems[productionOrderId] = newItems;

      return {
        ...state,
        singleItemsByProductionOrder: singleItems,
      };
    },
  ),
  on(
    AppActions.toggleSingleItemsByProductionOrderLoading,
    (state, { loading }) => {
      return {
        ...state,
        singleItemsByProductionOrderLoading: loading,
      };
    },
  ),

  on(AppActions.fetchNotificationsSuccess, (state, { result }) => ({
    ...state,
    notifications: result,
  })),

  on(AppActions.markNotificationAsRead, (state, { id }) => ({
    ...state,
    notifications: (state.notifications ?? []).map((notification) =>
      notification.rowKey === id
        ? { ...notification, isRead: true }
        : notification,
    ),
  })),
);

export function appReducer(state: AppState | undefined, action: Action) {
  return reducer(state, action);
}
