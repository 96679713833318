import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  CALIBRATION_FEATURE_KEY,
  CalibrationState,
} from './calibration.reducer';

export const selectCalibrationState = createFeatureSelector<CalibrationState>(
  CALIBRATION_FEATURE_KEY,
);

export const selectPeriodicCalibrations = () =>
  createSelector(
    selectCalibrationState,
    (state: CalibrationState) => state.periodicCalibrations,
  );

export const selectPeriodicCalibrationsLoading = () =>
  createSelector(
    selectCalibrationState,
    (state: CalibrationState) => state.periodicCalibrationsLoading,
  );

export const selectPeriodicCalibration = () =>
  createSelector(
    selectCalibrationState,
    (state: CalibrationState) => state.periodicCalibration,
  );

export const selectInProcessCalibrations = () =>
  createSelector(
    selectCalibrationState,
    (state: CalibrationState) => state.inProcessCalibrations,
  );

export const selectInProcessCalibrationsLoading = () =>
  createSelector(
    selectCalibrationState,
    (state: CalibrationState) => state.inProcessCalibrationsLoading,
  );

export const selectCalibrationMeasurementsModalData = () =>
  createSelector(
    selectCalibrationState,
    (state: CalibrationState) => state.measurementsModalData,
  );

export const selectCalibrationMeasurementsModalLoading = () =>
  createSelector(
    selectCalibrationState,
    (state: CalibrationState) => state.measurementsModalLoading,
  );

export const selectInProcessCalibration = () =>
  createSelector(
    selectCalibrationState,
    (state: CalibrationState) => state.inProcessCalibration,
  );

export const selectInProcessCalibrationLoading = () =>
  createSelector(
    selectCalibrationState,
    (state: CalibrationState) => state.inProcessCalibrationLoading,
  );
