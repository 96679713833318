import * as AlertingActions from '@core-app/state/alerting/alerting.actions';
import { AlertState } from '@enums';
import { Alert, Monitor, Recipient, RecipientGroup } from '@interfaces';
import { Action, createReducer, on } from '@ngrx/store';

export const ALERTING_FEATURE_KEY = 'alerting';

export interface AlertingState {
  alerts: null | Alert[];
  selectedAlert: Alert | null;
  selectedRecipientsFromAlert: Recipient[] | null;
  monitors: Monitor[];
  selectedMonitor: Monitor;
  receiverGroups: null | RecipientGroup[];
  selectedReceiverGroup: null | RecipientGroup;
  receivers: Recipient[] | null;
  approverGroups: null | RecipientGroup[];
  approvers: Recipient[] | null;
  selectedApproverGroup: null | RecipientGroup;
  approverRecipients: Recipient[] | null;
  recipientGroupToDelete: RecipientGroup | null;
  loading: boolean;
  recipientGroupsLoading: boolean;
  approverGroupsLoading: boolean;
}

export const initialAlertingState: AlertingState = {
  selectedAlert: {} as Alert,
  selectedRecipientsFromAlert: null,
  alerts: null,
  monitors: [],
  selectedMonitor: {} as Monitor,
  receiverGroups: null,
  selectedReceiverGroup: null,
  receivers: null,
  approvers: null,
  approverGroups: null,
  selectedApproverGroup: null,
  approverRecipients: null,
  recipientGroupToDelete: null,
  loading: false,
  recipientGroupsLoading: false,
  approverGroupsLoading: false,
};

const reducer = createReducer(
  initialAlertingState,
  on(AlertingActions.fetchMonitorsSuccess, (state, { result }) => ({
    ...state,
    monitors: result,
  })),
  on(AlertingActions.fetchMonitorByIdSuccess, (state, { result }) => ({
    ...state,
    selectedMonitor: result,
  })),
  on(AlertingActions.toggleRecipientGroupLoading, (state, { loading }) => ({
    ...state,
    recipientGroupsLoading: loading,
  })),
  on(AlertingActions.fetchRecipientGroupsSuccess, (state, { result }) => ({
    ...state,
    receiverGroups: result,
  })),
  on(AlertingActions.toggleApproverGroupLoading, (state, { loading }) => ({
    ...state,
    approverGroupsLoading: loading,
  })),
  on(AlertingActions.fetchApproversGroupsSuccess, (state, { result }) => ({
    ...state,
    approverGroups: result,
  })),
  on(AlertingActions.fetchReceiversFromGroupSuccess, (state, { result }) => ({
    ...state,
    receivers: result,
  })),
  on(AlertingActions.fetchApproversFromGroupSuccess, (state, { result }) => ({
    ...state,
    approvers: result,
  })),
  on(AlertingActions.fetchAlertsSuccess, (state, { result }) => ({
    ...state,
    alerts: result,
  })),
  on(
    AlertingActions.fetchAlertByIdSuccess,
    (state, { result, recipients }) => ({
      ...state,
      selectedAlert: result,
      selectedRecipientsFromAlert: recipients,
    }),
  ),
  on(AlertingActions.fetchAlertByIdFailure, (state) => ({
    ...state,
    selectedAlert: null,
  })),

  on(AlertingActions.createNewReceiverGroupSuccess, (state, { result }) => ({
    ...state,
    receiverGroups: [...result]?.sort(
      (a, b) =>
        new Date(a.timeStamp ?? 0).getTime() -
        new Date(b.timeStamp ?? 0).getTime(),
    ),
  })),
  on(AlertingActions.createNewApproverGroupSuccess, (state, { result }) => ({
    ...state,
    approverGroups: [...result]?.sort(
      (a, b) =>
        new Date(a.timeStamp ?? 0).getTime() -
        new Date(b.timeStamp ?? 0).getTime(),
    ),
  })),
  on(AlertingActions.setSelectedReceiverGroup, (state, { id }) => ({
    ...state,
    selectedReceiverGroup:
      state.receiverGroups?.find((group) => group.rowKey === id) || null,
  })),
  on(AlertingActions.setSelectedApproverGroup, (state, { id }) => ({
    ...state,
    selectedApproverGroup:
      state.approverGroups?.find((group) => group.rowKey === id) || null,
  })),
  on(AlertingActions.selectGroupToDelete, (state, { group }) => ({
    ...state,
    recipientGroupToDelete: group,
  })),

  on(AlertingActions.toggleAlertLoading, (state, { loading }) => ({
    ...state,
    loading,
  })),

  on(AlertingActions.approveAlertByIdSuccess, (state) => ({
    ...state,
    selectedAlert: {
      ...state.selectedAlert,
      state: AlertState.Approved,
    },
  })),

  on(AlertingActions.sendAlertByIdSuccess, (state) => ({
    ...state,
    selectedAlert: {
      ...state.selectedAlert,
      state: AlertState.Sent,
    },
  })),

  on(AlertingActions.deleteAlertByIdSuccess, (state) => ({
    ...state,
    selectedAlert: null,
  })),
);

export function alertingReducer(
  state: AlertingState | undefined,
  action: Action,
) {
  return reducer(state, action);
}
