import {
  BomNode,
  Document,
  Frequency,
  IncomingOrPeriodicInspection,
  InlineInspection,
  Measurement,
  ProcessParameter,
  Specification,
} from '@interfaces';
import { formatToDate } from './utils';

export const getFrequency = (
  inspection: IncomingOrPeriodicInspection,
): string => {
  const frequency: Frequency = {
    frequency: inspection.frequency,
    frequencyUnit: inspection.frequencyUnit,
    inspectionLot: inspection.inspectionLot,
    lotSize: inspection.lotSize,
    lotSizeUnit: inspection.lotSizeUnit,
  };

  const freqRes = [];
  if (frequency?.frequencyUnit != null) {
    freqRes.push(frequency?.frequency + ' / ' + frequency?.frequencyUnit);
  }
  if (frequency?.lotSizeUnit != null) {
    freqRes.push(frequency.lotSize + ' / ' + frequency.lotSizeUnit);
  }
  return freqRes.join('\n ');
};

export const getInspectionResults = (
  inspection: IncomingOrPeriodicInspection,
): string => {
  return `Count: ${inspection.measurements?.length}
OK: ${inspection.measurements?.filter(
    (measurement) => !measurement.measurementInvalid,
  ).length}
NOK: ${inspection.measurements?.filter(
    (measurement) => measurement.measurementInvalid,
  ).length}
    `;
};

export const getIncomingSpecification = (
  spec?: Specification,
  unit?: string,
): string => {
  if (!unit) {
    return '-';
  }

  const specification = [];
  if (spec?.nominalSize) {
    specification.push(`Nominal: ${spec.nominalSize} ${unit}`);
  }
  if (spec?.nominalSizeLowerLimit) {
    specification.push(`LSL: ${spec.nominalSizeLowerLimit} ${unit}`);
  }
  if (spec?.nominalSizeUpperLimit) {
    specification.push(`USL: ${spec.nominalSizeUpperLimit} ${unit}`);
  }

  return specification.join('\n');
};

export const getInlineSpecification = (
  inspection: InlineInspection,
  unit?: string,
): string => {
  const specification = [];

  if (typeof inspection.specification?.nominal === 'number') {
    specification.push(`Nominal: ${inspection.specification.nominal} ${unit}`);
  }

  if (typeof inspection.specification?.lsl === 'number') {
    specification.push(`LSL: ${inspection.specification.lsl} ${unit}`);
  }

  if (typeof inspection.specification?.usl === 'number') {
    specification.push(`USL: ${inspection.specification.usl} ${unit}`);
  }

  return specification.length ? specification.join('\n ') : '-';
};

export const getIncomingMeasurementUnit = (measurements?: Measurement[]) => {
  let unit: string | null = '';

  if (!measurements) {
    return;
  }

  measurements.forEach((meas: Measurement) => {
    if (!unit && meas.unit) {
      unit = meas.unit;
    }
  });

  return unit;
};

export const getDocuments = (inspection: IncomingOrPeriodicInspection) => {
  const documents: Document[] = [];

  inspection.measurements?.forEach((meas: Measurement) => {
    meas.documents?.forEach((doc: Document) => {
      documents.push(doc);
    });
  });

  return documents;
};

export const getInlineMeasurementUnit = (inspection: InlineInspection) => {
  return inspection.parameter?.unit;
};

export const median = (values: number[]) => {
  if (values.length === 0) {
    return 0;
  }

  values.sort((a, b) => a - b);

  const half = Math.floor(values.length / 2);

  if (values.length % 2) {
    return values[half];
  }

  return (values[half - 1] + values[half]) / 2.0;
};

export const getIncomingMeasurements = (measurements?: Measurement[]) => {
  if (!measurements) {
    return;
  }

  let maxMeasurement = measurements[0].measuredValue;
  let minMeasurement = measurements[0].measuredValue;

  const unit = getIncomingMeasurementUnit(measurements);

  measurements.forEach((meas: Measurement) => {
    if (meas.measuredValue > maxMeasurement) {
      maxMeasurement = meas.measuredValue;
    }
    if (meas.measuredValue < minMeasurement) {
      minMeasurement = meas.measuredValue;
    }
  });

  if (unit) {
    return (
      'Mdn: ' +
      parseFloat(
        median(measurements.map((meas) => meas.measuredValue)).toFixed(2),
      ) +
      ' ' +
      unit +
      '\nMax: ' +
      parseFloat(maxMeasurement.toFixed(2)) +
      ' ' +
      unit +
      '\nMin: ' +
      parseFloat(minMeasurement.toFixed(2)) +
      ' ' +
      unit
    );
  } else {
    return '-';
  }
};

// Value might be a timestamp string or a float number
function formatIfNumber(value?: string | number, precision = 3) {
  if (!value) {
    return;
  }

  // If it's a string, and not in number format, return the string
  if (isNaN(Number(value))) {
    return value;
  } else {
    return parseFloat(Number(value).toFixed(precision));
  }
}

function addSpaceBetweenUppercaseCharacters(str: string) {
  return str.replace(/([A-Z])/g, ' $1').trim();
}

function convertToReadableLabel(value: string) {
  return addSpaceBetweenUppercaseCharacters(
    value.charAt(0).toUpperCase() + value.slice(1),
  );
}

export const getInlineMeasurements = (inspection: InlineInspection) => {
  const strings: string[] = [];
  const properties: (keyof ProcessParameter['measurement'])[] = [
    'average',
    'min',
    'max',
    'p90',
    'p10',
    'value',
    'ok',
    'nOk',
    'total',
  ];

  // Create string for each property that has a value
  properties.forEach((property) => {
    if (inspection.parameter?.measurement[property]) {
      strings.push(
        `${convertToReadableLabel(property)}: ${formatIfNumber(
          inspection.parameter?.measurement[property],
        )}${
          inspection.parameter?.unit ? ` ${inspection.parameter?.unit}` : ''
        }`,
      );
    }
  });

  return strings.length ? strings.join('\n ') : '-';
};

export const processIncomingInspectionData = (
  inspection: IncomingOrPeriodicInspection,
  articleId: BomNode['articleId'],
  batchIds: BomNode['batchId'][],
  rootArticleId: BomNode['articleId'],
  rootBatchId: BomNode['batchId'],
): IncomingOrPeriodicInspection => {
  const {
    characteristic,
    characteristicId,
    inspectionLot,
    lotSize,
    lotSizeUnit,
    images,
    note,
    measurements,
  } = inspection;

  return {
    articleId,
    // TODO: Maybe this should be something like batchIds.join('-') instead?
    batchId: inspection.batchId,
    rootArticleId,
    rootBatchId,
    date: inspection.measurements?.length
      ? formatToDate(inspection.measurements[0].measuredValueTs)
      : null,
    characteristic,
    characteristicId,
    inspectionLot,
    lotSize,
    lotSizeUnit,
    images,
    note,
    measurements,
    derivedFrequency: getFrequency(inspection),
    derivedMeasurements: getIncomingMeasurements(inspection.measurements),
    derivedResult: getInspectionResults(inspection),
    derivedSpecification: getIncomingSpecification(
      inspection.specification,
      getIncomingMeasurementUnit(inspection.measurements),
    ),
    derivedDocuments: getDocuments(inspection),
    supplierName: inspection.supplierName,
  };
};

export const processInlineInspectionData = (
  inspection: InlineInspection,
  articleId: BomNode['articleId'],
  batchIds: BomNode['batchId'][],
  rootArticleId: BomNode['articleId'],
  rootBatchId: BomNode['batchId'],
): InlineInspection => {
  const {
    images,
    batch,
    serialNumber,
    station,
    operation,
    processType,
    checks,
    value,
    productionOrderId,
    parameter,
    specification,
    count,
  } = { ...inspection };

  return {
    articleId,
    // TODO: Maybe this should be something like batchIds.join('-') instead?
    batchId: inspection.batchId,
    productionOrderId,
    rootArticleId,
    rootBatchId,
    start: formatToDate(inspection.start || ''),
    end: formatToDate(inspection.end || ''),
    images,
    batch,
    serialNumber,
    station,
    operation,
    processType,
    checks,
    value,
    parameter,
    specification,
    count,
    derivedParameter: inspection.parameter?.displayName,
    derivedMeasurements: getInlineMeasurements(inspection),
    derivedSpecification: getInlineSpecification(
      inspection,
      getInlineMeasurementUnit(inspection),
    ),
    derivedProcessType:
      processType.charAt(0).toUpperCase() + processType.slice(1).toLowerCase(),
  };
};
