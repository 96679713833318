<header class="main-header">
  <div class="flex items-center">
    <div
      (click)="collapseSidebar()"
      *ngIf="unCollapsed"
      class="border-light-grey mr-2 cursor-pointer border border-l-0 p-2"
    >
      <fip-svg size="24" fill="#B9B9B9" name="collapse"></fip-svg>
    </div>
    <a routerLink="/">
      <!-- New icon -->
      <img
        *ngIf="
          showLogo &&
          featureFlags.getFeatureAreaVisibility('newFipLogo') === 'enabled'
        "
        class="h-5 pl-2 md:h-10 md:pl-4"
        src="assets/images/logos/fip.svg"
        alt="fip_logo"
      />
      <!-- Legacy icon -->
      <img
        *ngIf="
          showLogo &&
          featureFlags.getFeatureAreaVisibility('newFipLogo') !== 'enabled'
        "
        class="h-10 pl-4"
        src="assets/images/fip_logo.png"
        alt="fip_logo"
      />
    </a>
  </div>

  <fip-production-order-navigation
    class="center-navigation"
    *ngIf="router.url.includes('/parts')"
  ></fip-production-order-navigation>

  <div class="flex items-center justify-center">
    <!-- Support -->
    <!-- <a
      mat-icon-button
      color="primary"
      target="_blank"
      href="https://www.fraenkische.com/en/"
    >
      <mat-icon fontIcon="support_agent"></mat-icon>
    </a> -->

    <button
      mat-icon-button
      color="primary"
      *ngIf="featureFlags.getFeatureAreaVisibility('alerting') !== 'hidden'"
      [disabled]="
        featureFlags.getFeatureAreaVisibility('alerting') === 'disabled'
      "
      [matMenuTriggerFor]="notificationMenu"
    >
      <mat-icon
        [matBadge]="unreadCount$ | async"
        matBadgeColor="warn"
        matBadgeSize="small"
        aria-hidden="false"
        >notifications</mat-icon
      >
    </button>

    <!-- Notification Menu -->
    <mat-menu #notificationMenu="matMenu" class="notification-menu">
      <ng-container *ngIf="notifications$ | async as notifications">
        <ng-container *ngIf="notifications.length > 0; else noNotifications">
          <button
            mat-menu-item
            *ngFor="let notification of notifications"
            [ngClass]="{ unread: !notification.isRead }"
            (click)="navigateToAlert(notification)"
          >
            <span>{{ notification.message }}</span>
          </button>
        </ng-container>
        <ng-template #noNotifications>
          <button mat-menu-item disabled>
            <span>No new notifications</span>
          </button>
        </ng-template>
      </ng-container>
    </mat-menu>

    <button mat-icon-button color="primary" [matMenuTriggerFor]="menu">
      <mat-icon fontIcon="settings"></mat-icon>
    </button>

    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="reloadPermissions()">
        Reload permissions
      </button>
    </mat-menu>

    <div
      class="flex flex-row items-center text-xs xl:text-sm"
      *ngIf="user?.isLoggedIn"
    >
      <!-- User email -->
      <div>
        <span class="mx-2">{{ user?.username }}</span>
      </div>

      <!-- Logout button -->
      <div class="border-light-grey border-l pl-2">
        <button
          mat-flat-button
          (click)="logout()"
          class="hover:bg-accent-yellow text-dark-grey select-none px-2 py-1 !text-xs hover:border-black xl:!text-sm"
        >
          LOGOUT
        </button>
      </div>
    </div>
  </div>
</header>
