import { Component, DestroyRef, Inject, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  calibrationActions,
  selectCalibrationMeasurementsModalData,
  selectCalibrationMeasurementsModalLoading,
} from '@core-app/state/calibration';
import { SearchState } from '@core-app/state/search/search.reducer';
import { ComponentDataState } from '@enums';
import { TableColumn } from '@frontend-workspace/shared/src/lib/components/table/table.component';
import { ModalKey } from '@frontend-workspace/shared/src/lib/types/modal-key';
import { CalibrationMeasurement, Measurement } from '@interfaces';

import { Store } from '@ngrx/store';

@Component({
  selector: 'fip-measurements-modal',
  templateUrl: './calibration-measurements.modal.html',
  styleUrls: ['./calibration-measurements.modal.scss'],
  standalone: false,
})
export class CalibrationMeasurementsModalComponent implements OnInit {
  readonly modalKey: ModalKey = 'calibration-measurements';
  private readonly _store = inject(Store<SearchState>);
  private readonly _destroyRef = inject(DestroyRef);

  modalData$ = this._store.select(selectCalibrationMeasurementsModalData());
  dataState: ComponentDataState = ComponentDataState.Loading;
  ComponentDataState = ComponentDataState;
  loading$ = this._store.select(selectCalibrationMeasurementsModalLoading());
  tableData: CalibrationMeasurement[] = [];

  columns: TableColumn<CalibrationMeasurement>[] = [
    {
      index: 'mkId',
      label: 'MK ID',
      key: 'mkId',
    },
    {
      index: 'name',
      label: 'name',
      key: 'name',
    },

    {
      index: 'characteristicType',
      label: 'Characteristic Type',
      key: 'characteristicType',
    },
    {
      index: 'measureUnit',
      label: 'Measure Unit',
      key: 'measureUnit',
    },
    {
      index: 'shouldValue',
      label: 'Should Value',
      key: 'shouldValue',
    },
    {
      index: 'measuredValue',
      label: 'Measured Value',
      key: 'measuredValue',
    },
    {
      index: 'mkUsg',
      label: 'MK USG',
      key: 'mkUsg',
    },
    {
      index: 'mkOsg',
      label: 'MK OSG',
      key: 'mkOsg',
    },
    {
      index: 'pruefmittelType',
      label: 'Pruefmittel Type',
      key: 'pruefmittelType',
    },
    {
      index: 'result',
      label: 'Result',
      key: 'result',
    },
    {
      index: 'measureDate',
      label: 'Measure Date',
      key: 'measureDate',
    },
  ];

  displayedColumns: (keyof CalibrationMeasurement)[] = [
    'mkId',
    'name',
    'characteristicType',
    'measureUnit',
    'shouldValue',
    'measuredValue',
    'mkUsg',
    'mkOsg',
    'pruefmittelType',
    'result',
    'measureDate',
  ];
  xTicksVals: number[] = [];
  xTicksLabels: string[] = [];
  yAxisLabel = 'Value';

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      articleId: string;
      batchId: string;
    },
  ) {}

  ngOnInit(): void {
    this._store.dispatch(
      calibrationActions.getMeasurementsData({
        articleId: this.data.articleId,
        batchId: this.data.batchId,
      }),
    );

    this.modalData$
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe((data) => {
        if (data) {
          this.tableData = data;
          this.setupData(data);
        }
      });
  }

  setupData(measurements: Measurement[] | CalibrationMeasurement[]) {
    this.setDataState(measurements);
  }

  setDataState(data: Measurement[] | CalibrationMeasurement[]) {
    if (data.length === 0) {
      this.dataState = ComponentDataState.NoData;
    } else {
      this.dataState = ComponentDataState.HasData;
    }
  }
}
