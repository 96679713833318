import { formatToDate } from '@frontend-workspace/shared/src/lib/helpers';
import {
  CalibrationMeasurement,
  InProcessCalibration,
  PeriodicCalibration,
  PeriodicCalibrationDetail,
} from '@frontend-workspace/shared/src/lib/interfaces';
import { Action, createReducer, on } from '@ngrx/store';
import { calibrationActions } from './calibration.actions';

export const CALIBRATION_FEATURE_KEY = 'calibration';

export interface CalibrationState {
  periodicCalibrations: null | PeriodicCalibration[];
  periodicCalibrationsLoading: boolean;
  periodicCalibration: null | PeriodicCalibrationDetail[];
  periodicCalibrationLoading?: boolean;
  inProcessCalibrations: null | InProcessCalibration[];
  inProcessCalibrationsLoading: boolean;
  measurementsModalData: null | CalibrationMeasurement[];
  measurementsModalLoading: boolean;
  inProcessCalibration: null | InProcessCalibration;
  inProcessCalibrationLoading: boolean;
}

export const initialCalibrationState: CalibrationState = {
  periodicCalibrations: null,
  periodicCalibrationsLoading: false,
  periodicCalibration: [],
  periodicCalibrationLoading: false,
  inProcessCalibrations: null,
  inProcessCalibrationsLoading: false,
  measurementsModalData: null,
  measurementsModalLoading: false,
  inProcessCalibration: null,
  inProcessCalibrationLoading: false,
};

const reducer = createReducer(
  initialCalibrationState,
  on(
    calibrationActions.getPeriodicCalibrationsSuccess,
    (state, { result }) => ({
      ...state,
      periodicCalibrations: result.map
        ? result.map((item) => ({
            ...item,
            lastInspection: formatToDate(item.lastInspection) || '',
            firstInspection: formatToDate(item.firstInspection) || '',
            nextInspection: formatToDate(item.nextInspection) || '-',
          }))
        : [],
    }),
  ),
  on(
    calibrationActions.togglePeriodicCalibrationLoading,
    (state, { loading }) => ({
      ...state,
      periodicCalibrationsLoading: loading,
    }),
  ),
  on(
    calibrationActions.getPeriodicCalibrationByIDSuccess,
    (state, { result }) => ({
      ...state,
      periodicCalibration: result.map((item) => ({
        ...item,
        inspectionDate: formatToDate(item.inspectionDate) || '',
        createdOn: formatToDate(item.createdOn) || '',
      })),
    }),
  ),
  on(
    calibrationActions.getInProcessCalibrationsSuccess,
    (state, { result }) => ({
      ...state,
      inProcessCalibrations: result.map((item) => ({
        ...item,
        createdOn: formatToDate(item.createdOn) || '',
        workstepDecisionDate: formatToDate(item.workstepDecisionDate) || '',
        orderPositionCalibrationDate:
          formatToDate(item.orderPositionCalibrationDate) || '',
        measureDate: formatToDate(item.measureDate) || '',
      })),
    }),
  ),
  on(calibrationActions.getMeasurementsDataSuccess, (state, { result }) => ({
    ...state,
    measurementsModalData: result,
  })),
  on(
    calibrationActions.toggleMeasurementsDataLoading,
    (state, { loading }) => ({
      ...state,
      measurementsModalLoading: loading,
    }),
  ),
  on(
    calibrationActions.getInProcessCalibrationByIDSuccess,
    (state, { result }) => ({
      ...state,
      inProcessCalibration: {
        ...result,
        createdOn: formatToDate(result.createdOn) || '',
        measureDate: formatToDate(result.measureDate) || '',
      },
    }),
  ),
);

export function calibrationReducer(
  state: CalibrationState | undefined,
  action: Action,
) {
  return reducer(state, action);
}
