import { inject, Injectable } from '@angular/core';
import { CalibrationService } from '@frontend-workspace/shared/src';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { map, Observable, switchMap, tap } from 'rxjs';
import { calibrationActions } from './calibration.actions';

@Injectable()
export class CalibrationEffects {
  private readonly _actions$ = inject(Actions);
  private readonly _calibrationService = inject(CalibrationService);
  private _store = inject(Store);

  getPeriodicCalibrations$ = createEffect(
    (): Observable<Action> =>
      this._actions$.pipe(
        ofType(calibrationActions.getPeriodicCalibrations),
        tap(() =>
          this._store.dispatch(
            calibrationActions.togglePeriodicCalibrationLoading({
              loading: true,
            }),
          ),
        ),
        switchMap(({ companyId }) => {
          return this._calibrationService
            .getPeriodicCalibrations(companyId)
            .pipe(
              map((response) => {
                const result = response.data === null ? [] : response.data;
                return calibrationActions.getPeriodicCalibrationsSuccess({
                  result,
                });
              }),
              tap(() =>
                this._store.dispatch(
                  calibrationActions.togglePeriodicCalibrationLoading({
                    loading: false,
                  }),
                ),
              ),
            );
        }),
      ),
  );

  getPeriodicCalibrationByID$ = createEffect(
    (): Observable<Action> =>
      this._actions$.pipe(
        ofType(calibrationActions.getPeriodicCalibrationByID),
        tap(() =>
          this._store.dispatch(
            calibrationActions.togglePeriodicCalibrationLoading({
              loading: true,
            }),
          ),
        ),
        switchMap(({ id }) => {
          return this._calibrationService.getPeriodicCalibrationByID(id).pipe(
            map((response) => {
              const result = response.data === null ? [] : response.data;
              return calibrationActions.getPeriodicCalibrationByIDSuccess({
                result,
              });
            }),
            tap(() =>
              this._store.dispatch(
                calibrationActions.togglePeriodicCalibrationLoading({
                  loading: false,
                }),
              ),
            ),
          );
        }),
      ),
  );

  getInProcessCalibrations$ = createEffect(
    (): Observable<Action> =>
      this._actions$.pipe(
        ofType(calibrationActions.getInProcessCalibrations),
        tap(() =>
          this._store.dispatch(
            calibrationActions.togglePeriodicCalibrationLoading({
              loading: true,
            }),
          ),
        ),
        switchMap(({ id }) => {
          return this._calibrationService.getInProcessCalibrations(id).pipe(
            map((response) => {
              const result = response.data === null ? [] : response.data;
              return calibrationActions.getInProcessCalibrationsSuccess({
                result,
              });
            }),
            tap(() =>
              this._store.dispatch(
                calibrationActions.togglePeriodicCalibrationLoading({
                  loading: false,
                }),
              ),
            ),
          );
        }),
      ),
  );

  getCalibrationMeasurementsData$ = createEffect(
    (): Observable<Action> =>
      this._actions$.pipe(
        ofType(calibrationActions.getMeasurementsData),
        tap(() =>
          this._store.dispatch(
            calibrationActions.togglePeriodicCalibrationLoading({
              loading: true,
            }),
          ),
        ),
        switchMap(({ articleId, batchId }) => {
          return this._calibrationService
            .getMeasurementsData(articleId, batchId)
            .pipe(
              map((response) => {
                const result = response.data === null ? [] : response.data;
                return calibrationActions.getMeasurementsDataSuccess({
                  result,
                });
              }),
              tap(() =>
                this._store.dispatch(
                  calibrationActions.togglePeriodicCalibrationLoading({
                    loading: false,
                  }),
                ),
              ),
            );
        }),
      ),
  );

  getInProcessCalibrationByID$ = createEffect(
    (): Observable<Action> =>
      this._actions$.pipe(
        ofType(calibrationActions.getInProcessCalibrationByID),
        tap(() =>
          this._store.dispatch(
            calibrationActions.togglePeriodicCalibrationLoading({
              loading: true,
            }),
          ),
        ),
        switchMap(({ id }) => {
          return this._calibrationService.getInProcessCalibrationByID(id).pipe(
            map((response) => {
              const result = response.data;
              return calibrationActions.getInProcessCalibrationByIDSuccess({
                result,
              });
            }),
            tap(() =>
              this._store.dispatch(
                calibrationActions.togglePeriodicCalibrationLoading({
                  loading: false,
                }),
              ),
            ),
          );
        }),
      ),
  );
}
