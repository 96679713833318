import { Injectable, inject } from '@angular/core';
import { UserService } from '@frontend-workspace/shared/src';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, from, map, switchMap } from 'rxjs';
import { getUserFeatureFlags, setUserFeatureFlags } from './user.actions';

@Injectable()
export class UserEffects {
  private actions$ = inject(Actions);
  private readonly _userService = inject(UserService);

  getUserFeatureFlags$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(getUserFeatureFlags),
        switchMap(() =>
          from(this._userService.getUserFeatureFlags()).pipe(
            map((response) => response.data),
            map((featureFlags) => setUserFeatureFlags({ featureFlags })),
          ),
        ),
      ),
  );
}
