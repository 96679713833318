<div>
  <mat-dialog-content>
    Are you sure you want to approve this item?
  </mat-dialog-content>

  <mat-dialog-actions class="!justify-center">
    <fip-button
      (clickEvent)="modalService.dialogRef.close(false)"
      label="Cancel"
      textColor="black"
      classes="mr-4 !bg-white"
    ></fip-button>

    <fip-button
      (clickEvent)="modalService.dialogRef.close(true)"
      label="Confirm"
      [classes]="'!bg-accent-green'"
      textColor="white"
    ></fip-button>
  </mat-dialog-actions>
</div>
