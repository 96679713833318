<div class="measurements-modal">
  <div class="flex justify-center">
    <ng-container *ngIf="loading$ | async; else dataLoaded">
      <fip-loading-spinner
        class="absolute left-2/4 top-2/4"
      ></fip-loading-spinner>
    </ng-container>
    <ng-template #dataLoaded>
      <ng-container *ngIf="dataState === ComponentDataState.HasData">
        <fip-table
          [data]="tableData"
          [columns]="columns"
          [displayedColumns]="displayedColumns"
          [paginated]="true"
          [pageSizeOptions]="[10, 25, 50]"
        ></fip-table>
      </ng-container>
      <ng-container *ngIf="dataState === ComponentDataState.NoData">
        No data available.
      </ng-container>
    </ng-template>
  </div>
</div>
