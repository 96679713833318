import { Component, inject } from '@angular/core';
import { FeatureFlagsService } from '@core-app/services/feature-flags.service';
import { showModal } from '@core-app/state/ui';
import { ModalKey } from '@frontend-workspace/shared/src/lib/types/modal-key';
import { featureVisibility, productArea } from '@interfaces';
import { Store } from '@ngrx/store';

type MenuItem = {
  label: string;
  icon?: string;
  link?: string;
  children?: MenuItem[];
  disabled?: boolean;
  modalKey?: ModalKey;
  visibility?: featureVisibility;
  feature?: string;
  productArea?: productArea;
};

@Component({
  selector: 'fip-vertical-nav',
  templateUrl: './vertical-nav.component.html',
  styleUrls: ['./vertical-nav.component.scss'],
})
export class VerticalNavbarComponent {
  private readonly _store = inject(Store);
  readonly featureFlagService = inject(FeatureFlagsService);

  menuItems: MenuItem[] = [
    {
      label: 'Home',
      icon: 'home',
      link: '/',
    },
    {
      label: 'Part search',
      icon: 'search',
      children: [
        { label: 'Part search', modalKey: 'part-search_part-search' },
        {
          label: 'Incoming Inspections by Part',
          modalKey: 'part-search_incoming-inspections',
        },
        {
          label: 'Inline Inspections by Part',
          modalKey: 'part-search_inline-inspections',
        },
        {
          label: 'Periodic Inspections by Part',
          modalKey: 'part-search_periodic-inspections',
        },
      ],
    },
    {
      label: 'Inspections',
      icon: 'science',
      children: [
        {
          label: 'Incoming Inspections',
          modalKey: 'inspections_incoming-inspections',
        },
        {
          label: 'Inline Inspections',
          modalKey: 'inspections_inline-inspections',
        },
        {
          label: 'Periodic Inspections',
          modalKey: 'inspections_periodic-inspections',
        },
      ],
    },
    {
      label: 'SPC',
      icon: 'query_stats',
      productArea: 'SPC',
      children: [
        {
          label: 'Article Selector',
          modalKey: 'spc_article-search',
        },
      ],
    },
    {
      label: 'Production',
      icon: 'local_shipping',
      productArea: 'production',
      children: [
        { label: 'Stock', modalKey: 'production_planned_bom' },
        {
          label: '8D reports ',
          link: '/production/eight-d-reports',
          feature: '8D Reports',
        },
        {
          label: 'OEE dashboard ',
          link: '/production/oee-dashboard',
          feature: 'oee',
        },
        {
          label: 'Orders',
          link: '/production/orders',
          feature: 'orders',
        },
        {
          label: 'Deliveries',
          link: '/production/deliveries',
          feature: 'deliveries',
        },
      ],
    },
    {
      label: 'Calibration',
      icon: 'biotech',
      link: '/calibration',
      disabled: true,
      productArea: 'calibration',
    },
    {
      label: 'Alerting',
      icon: 'priority_high',
      link: '/alerting',
      productArea: 'alerting',
    },
  ];

  addClassToBody() {
    document.body.classList.add('vertical-nav-open');
  }

  removeClassFromBody() {
    // We need a little timeout in order to remove the class only after the hide animation is done
    setTimeout(() => {
      document.body.classList.remove('vertical-nav-open');
    }, 500);
  }

  displayModal(modalKey: ModalKey) {
    this._store.dispatch(showModal({ modalKey, width: '900px' }));
  }
}
